<template>
    <v-dialog :value="value"  @input="$emit('input', $event)" persistent max-width="290">
        <template v-slot:activator="{ on, attrs }">
            <v-icon color="red" v-bind="attrs" v-on="on">mdi-delete</v-icon>
        </template>
        <v-card>
            <v-card-title class="text-h5" style="color: red;">
                {{ $t('are you sure you want delete') }}؟
            </v-card-title>

            <v-spacer></v-spacer>
            <v-btn color="blue" text @click="cancel">
                {{ $t('cancel') }}
            </v-btn>
            <v-btn color="blue" text @click="deleteEmplo">
                <span > {{ $t('submit') }}</span>
                
            </v-btn>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import { mapActions} from "vuex";

export default {
    props: {
    form: {
      type: Object,
      default: () => {
      },
      required: true
    },
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },
    data() {
        return {
            
        }
    },
    methods: {
        ...mapActions({
      fetch: "employees/fetch",
     
     
    }),
       async deleteEmplo() {
        
        let res= await axios.delete(`/hr/employee/${this.form.id}`);     
        if(res.status==200){

            this.$emit('input', false);
            this.form=null;
            this.value=false;
            this.$Notifications(
              this.$t('delete success'),
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.fetch();
        }
    },
    cancel(){
        this.$emit('input',false);
        this.form=null;
        this.value=false;
    },
      
    }
}
</script>