<template>
  <div>
    <v-container class="px-10">
      <v-row>
        <v-col md="2" class="mr-2">
          <v-btn color="green" class="white--text" @click="handleCreate">
            <v-icon size="20">mdi-plus</v-icon>
            {{ $t("add employee") }}
          </v-btn>
        </v-col>
        <v-col md="2" class="mr-2">
          <ExportExcel endPoint="/hr/employee/export" :params="filters" />
        </v-col>

        <v-col md="12" class="mt-8">
          <h3 class="mb-2">{{ $t('publicRecordEmployee') }}</h3>
          <v-row>
            <v-col md="3">
              <AutoCompleteField :label="$t('hr.time.emp-name')" endPoint="/hr/employee/auto-complete"
                v-model="filters.id" />
            </v-col>
            <v-col md="3">
              <AutoCompleteField :label="$t('initiate.Shift')" endPoint="/hr/shift/auto-complete"
                v-model="filters.shift" />
            </v-col>
            <v-col md="3">
              <AutoCompleteField :label="$t('hr.employee.contract')" endPoint="/hr/contract/auto-complete"
                v-model="filters.contract" />
            </v-col>
            <v-col md="3"></v-col>
            <v-col md="3" class="mb-3 py-0">
              <v-btn color="green" dark block @click="fetch(filters)">{{ $t('search') }}</v-btn>
            </v-col>
            <v-col md="3" class="mb-3 py-0">
              <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
            </v-col>
          </v-row>
          <v-container id="regular-tables" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode">
              <thead class="table-heading" v-if="!$store.state.employees.loading">
                <tr>
                  <th v-for="(name, i) in headers" :key="i">
                    {{ $t(name.text) }}
                  </th>
                </tr>
              </thead>
              <div class="table__spinnerWrapper" v-if="$store.state.employees.loading">
                <app-spinner></app-spinner>
              </div>
              <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!$store.state.employees.loading">
                <tr v-for="(item, idx) in data" :key="idx">
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.last_name }}</td>
                  <td>{{ item.contract.name }}</td>
                  <td>{{ item.shift.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.calculate_start_date }}</td>
                  <td>
                    <v-row>
                      <v-icon color="red" style="cursor: pointer"  @click="handleDelete(item)">mdi-delete
                    </v-icon>
                   
                    <v-icon color="blue"  style="cursor: pointer" @click="handleEdit(item)">mdi-pencil
                    </v-icon>
                    <v-icon color="green" style="cursor: pointer" 
                      @click="$router.push(`/HR/EditEmployee/${item.id}`)">mdi-eye
                    </v-icon>
                    </v-row>
                   
                  </td>
                </tr>
              </v-fade-transition>
            </v-simple-table>
            <div class="d-flex justify-end mt-5">
              <v-row>
                <v-col md="5 ">
                  <v-switch @change="goToAll" v-model="is_all" color="green" :label="$t('Show All')"></v-switch>
                </v-col>
                <app-pagination v-if="(!$store.state.employees.loading & (paginated))" :disable-pagination="!paginated"
                  :totalPages="$store.state.employees.serverTotal" :page="$store.state.employees.page"
                  @PaginationValue="applyPagination($event)"></app-pagination>
              </v-row>
            </div>
          </v-container>

        </v-col>
      </v-row>
      <!-- <edit-employee v-if="this.item" v-model="displayEdit" :form="item"></edit-employee> -->
      <delete-employee v-if="displaydel" v-model="displaydel" :form="item"></delete-employee>
    </v-container>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditEmployee from "@/views/dashboard/modules/HR/EditEmployee.vue";
import DeleteEmployee from "@/views/dashboard/modules/HR/DeleteEmployee.vue";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
import ExportExcel from "../../components/core/ExportExcel.vue";
import spinner from "../../../../components/base/spinner.vue";
import pagination from "../../../../components/base/pagination.vue";

export default {
  components: {
    EditEmployee, DeleteEmployee, AutoCompleteField, ExportExcel, appSpinner: spinner,
    appPagination: pagination,
  },
  data() {
    return {
      filters: {
        id: null,
        shift: null,
        contract: null,
      },
      displaydel: false,
      options: {},
      item: null,
      display: false,
      displayEdit: false,
      search: '',
      is_all: false,
      paginated: true,
    }
  },
  mounted() {
    this.fetch()
  },
  computed: {
    ...mapGetters({
      data: 'employees/data',
      headers: 'employees/headers'
    })

  },
  watch: {
    "$store.state.employees.options": function () {
      this.fetch()
    },
  },
  methods: {
    ...mapActions({
      fetch: "employees/fetch",
      setEmployee: 'employees/setEmployee'
    }),
    applyPagination(paginationValue) {
      this.page = paginationValue;
      let params = {
        size: 10,
        page: this.page,
        paginated: this.paginated,
        ...this.filters,
      }
      this.fetch(params);
    },
    goToAll() {
      if (this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      let params = {
        size: 10,
        page: this.page,
        paginated: this.paginated,
        ...this.filters,
      }
      this.fetch(params);
    },
    clearFilter() {
      this.filters.id = undefined;
      this.filters.shift = undefined;
      this.filters.contract = undefined;
      this.fetch();
    },
    handleDisplay(item) {
      this.item = item
      this.displayEdit = true
    },
    handleDelete(item) {
      this.item = item
      this.displaydel = true
    },
    handleEdit(item) {
      this.setEmployee(item)
      this.$router.push('/HR/CreateEmpolyee')
    },
    handleCreate() {
      this.setEmployee(null)
      this.$router.push('/HR/CreateEmpolyee')
    },
  },
}
</script>
<style></style>
